<template>
  <div
    class="container"
    v-title
    id="malfunctionStatistics"
    :data-title="$t('i18n.malfunctionStatistics')"
  >
    <div id="outer-title">{{ $t("i18n.malfunctionStatistics") }}</div>
    <el-date-picker
      v-model="dateRange"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :shortcuts="shortcuts"
      @change="initData"
    ></el-date-picker>
    <jl-chart name="option" class="chartCircle" :columns="pieOption"></jl-chart>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlChart from "../../components/chart";
export default {
  name: "FaultStatic",
  components: {
    jlChart,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      shortcuts: [
        {
          text: "最近一周",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })(),
        },
        {
          text: "最近一个月",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })(),
        },
        {
          text: "最近三个月",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })(),
        },
      ],
      dateRange: "",
      pieOption: {
        title: t("i18n.malfunctionStatistics"),
        type: "pie",
        series: [
          {
            minAngle: 5, //最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
            avoidLabelOverlap: true, //是否启用防止标签重叠策略
            radius: ["35%", "55%"],
            itemStyle: {
              normal: {
                label: {
                  formatter: "{b}:{d}%",
                },
              },
            },
            data: [],
          },
        ],
      },
    });

    const init = () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      state.dateRange = [start, end];
      initData();
    };

    const initData = async () => {
      let { data } = await proxy.$api.maintenance.getFaultStatic({
        beginTime: proxy.$defined.format(state.dateRange[0]) + " 00:00:00",
        endTime: proxy.$defined.format(state.dateRange[1]) + " 00:00:00",
      });
      state.pieOption.series[0].data = data;
    };

    init();

    return {
      ...toRefs(state),
      initData,
    };
  },
};
</script>

<style lang="scss">
#malfunctionStatistics {
  .el-date-editor {
    position: absolute;
    z-index: 2;
    top: 45px;
    left: 45px;
  }
}
@media screen and (max-width: 768px) {
  #malfunctionStatistics {
    .el-date-editor {
      top: 65px;
      left: 55px;
    }
  }
}
</style>
